<template>
    <b-modal :ref="modalRef" hide-header hide-footer class="text-center" centered size="sm" @hidden="destroyModal"
             :no-close-on-backdrop="type === 'loading'" :no-close-on-esc="type === 'loading'">
        <span :is="icon"></span>
        <div class="text-center">
            <p>{{ message }}</p>
            <b-button :variant="type" v-if="type !== 'loading'" @click="hideModal">Ok</b-button>
        </div>
    </b-modal>
</template>

<script>
    const SuccessIcon = () => import("@/components/alert_icons/SuccessIcon");
    const WarningIcon = () => import("@/components/alert_icons/WarningIcon");
    const DangerIcon  = () => import("@/components/alert_icons/DangerIcon");
    const LoadingIcon = () => import("@/components/alert_icons/LoadingIcon");

    import modalMixin from '@/mixin/modalMixin';

    export default {
        name: "ModalAlert",
        mixins: [modalMixin],
        components: {DangerIcon, WarningIcon, SuccessIcon, LoadingIcon},
        props: {
            type: {
                type: String,
                default: 'success'
            },
            message: {
                type: String
            },
        },
        data: () => ({
            modalRef: 'alertIconModal'
        }),
        computed: {
            icon() {
                return this.type + '-icon';
            }
        }
    }
</script>

<style scoped>
    .sa {
        margin: auto;
    }
</style>